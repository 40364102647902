/** @jsx jsx */
import { jsx, Text, Box, Flex } from 'theme-ui'
import Divider from '../../elements/divider'
import Inner from '../../elements/inner'
import Content from '../../elements/content'
import Svg from '../svg'
import { UpDown, UpDownWide } from '../../styles/animations'
import { MiddleImage } from './middle-image'

export const HistoryBusiness = ({
  top,
  height,
}: {
  top: string
  height: string
}) => (
  <div>
    <Content sx={{ top, height }}>
      <Inner>
        <MiddleImage />
      </Inner>
    </Content>
  </div>
)
