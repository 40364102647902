/** @jsx jsx */
import React from 'react'
import { jsx, Box } from 'theme-ui'

export const MiddleImage = () => (
  <React.Fragment>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: '200px',
      }}
    >
      <img
        src="/image.webp"
        alt="coding image"
        sx={{
          objectFit: 'contain',
          maxWidth: '100%',
          margin: '0 auto 32px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: ['column', 'column', 'column', 'row', 'row', 'row'],
          gap: ['3rem', '5rem'],
          maxWidth: ['100%', '100%', '100%', '1128px', '1128px', '1128px'],
          margin: '0 auto 32px',
        }}
      >
        <Box
          sx={{
            fontSize: ['1.2rem', '1.2rem', '2rem'],
            fontWeight: 700,
            lineHeight: 1.25,
            letterSpacing: '0.025em',
            color: 'text',
            textAlign: [
              'center',
              'center',
              'center',
              'start',
              'start',
              'start',
            ],
            flex: '1',
          }}
        >
          SYSTEM ENGINEERING SERVICE
        </Box>
        <Box
          sx={{
            fontSize: ['0.7rem', '0.8rem', '0.9rem'],
            fontWeight: 300,
            lineHeight: 1.5,
            letterSpacing: '0.025em',
            color: 'text',
            display: 'flex',
            flexDirection: 'column',
            gap: '3rem',
            flex: '1',
          }}
        >
          <Box>
            <h3>1. エンジニア向けエージェントビジネス</h3>
            <br />
            レベルアップしたいですか？私たちのエージェントビジネスは、専門家向けのプロジェクトマッチング、キャリア開発、ネットワーキングの機会を提供します。ソフトウェア開発からデータ分析まで、あなたの専門性を発揮できるさまざまなプロジェクトがここにあります。柔軟な勤務環境で、あなたの価値を最大化しましょう。
          </Box>
          <Box>
            <h3>2. グローバルプロジェクトでエンジニアリングキャリアを拡大</h3>
            <br />
            世界中のクライアントとのプロジェクトを通じて、あなたのエンジニアリング能力を広げたい場合、私たちがお手伝いします。競争力のある報酬を得ながら、あなたの技術をグローバル市場に展開しましょう。プロジェクト管理からコミュニケーションまでサポートし、あなたが目標を成功裏に達成できるよう努力します。今すぐ私たちと一緒に、あなたのキャリアを次の段階へと進めましょう。
          </Box>
        </Box>
      </Box>
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <img
        src="/image2.webp"
        alt="coding image"
        sx={{
          objectFit: 'contain',
          maxWidth: '100%',
          margin: '0 auto 32px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: ['column', 'column', 'column', 'row', 'row', 'row'],
          gap: ['3rem', '5rem'],
          maxWidth: ['100%', '100%', '100%', '1128px', '1128px', '1128px'],
          margin: '0 auto 32px',
        }}
      >
        <Box
          sx={{
            fontSize: ['1.2rem', '1.2rem', '2rem'],
            fontWeight: 700,
            lineHeight: 1.25,
            letterSpacing: '0.025em',
            color: 'text',
            textAlign: [
              'center',
              'center',
              'center',
              'start',
              'start',
              'start',
            ],
            flex: '1',
          }}
        >
          YOUTUBE ANALYTICS TOOLS
        </Box>
        <Box
          sx={{
            fontSize: ['0.7rem', '0.8rem', '0.9rem'],
            fontWeight: 300,
            lineHeight: 1.5,
            letterSpacing: '0.025em',
            color: 'text',
            display: 'flex',
            flexDirection: 'column',
            gap: '3rem',
            flex: '1',
          }}
        >
          <Box>
            <h3>1. ビデオ検索および分析</h3>
            <br />
            YouTubeのようなプラットフォームでビデオコンテンツを検索し、再生回数、いいね数、コメント数などのデータを分析してユーザーに提供するツールです。
          </Box>
          <Box>
            <h3>2. トレンドおよびパフォーマンスモニタリング</h3>
            <br />
            特定のビデオやチャンネルのパフォーマンスをリアルタイムで追跡し、人気のコンテンツトレンドを把握してマーケティング戦略の立案に役立てます。
          </Box>
        </Box>
      </Box>
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: ['column', 'column', 'column', 'row', 'row', 'row'],
          gap: ['3rem', '5rem'],
          maxWidth: ['100%', '100%', '100%', '1128px', '1128px', '1128px'],
          margin: '60px auto 60px',
        }}
      >
        <Box
          sx={{
            fontSize: ['1.2rem', '1.2rem', '2rem'],
            fontWeight: 700,
            lineHeight: 1.25,
            letterSpacing: '0.025em',
            color: 'text',
            textAlign: [
              'center',
              'center',
              'center',
              'start',
              'start',
              'start',
            ],
            flex: '1',
          }}
        >
          Our Partners
        </Box>
      </Box>
      <img
        src="/ebay.png"
        alt="ebay image"
        sx={{
          objectFit: 'contain',
          maxWidth: '100%',
          margin: '0 auto 32px',
          width: '240px',
          height: '96xp',
        }}
      />
    </Box>
  </React.Fragment>
)
